<template>
	<div class="wizard-finance">

		<div class="wizard-finance__intro col-md-10 middle text-center">
			<h3 class="text-center section-title">DID YOU KNOW</h3>
			<p>You have the option to lease your order for as little as {{leaseLowest.amount | currency}} per month, providing optimal cash flow and ROI on your cooperage. Renting kegs provides necessary capital for you to use to operate and expand your business.</p>
		</div>

		<div class="wizard-finance__boxes">
			<div class="wizard-finance__box">
				<div class="wizard-finance__box-inner text-center">
					<h4 class="wizard-finance__box-title">Rental Program</h4>
					<p class="wizard-finance__box-description">Our Rental Program, or Fair Market Value leases provide the lowest monthly payments and gives you the flexibility to make your purchasing decision at the end of the lease.</p>

					<ul class="wizard-finance__box-checks list-unstyled">
						<li>Lowest monthly payment available</li>
						<li>Ability to obtain more kegs</li>
						<li>More flexibility at the end of the lease term</li>
					</ul>

					<div class="wizard-finance__box-prices">
						<div v-for="(payment, key) in leasePayments.fml" :key="key">
							<strong>{{payment.amount | currency}}/mo</strong>
							<span>{{payment.term}} Months</span>
						</div>
					</div>

					<a @click="apply('fml')" class="btn btn-primary btn-block">Apply Now</a>
				</div>
			</div>

			<div class="wizard-finance__box">
				<div class="wizard-finance__box-inner text-center">
					<h4 class="wizard-finance__box-title">Lease-to-Own Program</h4>
					<p class="wizard-finance__box-description">Our Lease-to-Own Program, or &ldquo;Finance Lease&rdquo; leads to ownership of your kegs at the end of the lease for $1.00.</p>

					<ul class="wizard-finance__box-checks list-unstyled">
						<li>Lock in costs upfront</li>
						<li>Lease to own</li>
						<li>Own your kegs after last payment</li>
						<li>Budget your purchasing power</li>
					</ul>

					<div class="wizard-finance__box-prices list-unstyled">
						<div v-for="(payment, key) in leasePayments.fl" :key="key">
							<strong>{{payment.amount | currency}}/mo</strong>
							<span>{{payment.term}} Months</span>
						</div>
					</div>

					<a @click="apply('fl')" class="btn btn-primary btn-block">Apply Now</a>
				</div>
			</div>
		</div>

		<a href="tel:17274999930"><img src="~@/assets/images/banner-customization.png" width="903" height="256" class="max-width"></a>
	</div>
</template>

<script>
export default {
	name: 'wizardFinance',
	computed: {
		cart() {
			return this.$store.getters['cart/cart']
		},
		leasePayments() {
			return this.$store.getters['cart/leasePayments']
		},
		leaseLowest() {
			return this.$store.getters['cart/leaseLowest']
		},
	},
	mounted() {
		this.$store.dispatch('cart/load')
	},
	methods: {
		apply(type) {
			const finance = {
				type,
				payments: this.leasePayments[type],
			}

			this.$store.dispatch('cart/updateFinancing', finance)
				.then(() => {
					this.$router.push({ name: 'step3application' })
				})
		},
	},
}
</script>

<style scoped lang="scss">
@import '~@/styles/partials/icons';

.wizard-finance {

	&__intro {
		margin-bottom: $line-lg;
	}

	&__boxes {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		margin-bottom: $line-lg;

		@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
		}

		@media screen and (max-width: map-get($grid-breakpoints,'sm')) {
			display: block;
		}
	}

	&__box {
		background: #F4F4F4;
		border: 2px solid #fff;
		box-shadow: 0px 0px 0px 2px color_('brown','light') inset, 0 0 0px 4px color_('brown','light');
		// display: flex;
		// flex: 0 1 48%;
		margin: 0 2%;
		width: 48%;

		@media screen and (max-width: map-get($grid-breakpoints,'sm')) {
			width: 100%;
			padding: 12px;
			margin: 0 0 $line;
			display: block;
		}

		&-inner {
			// display: flex;
			// flex-direction: column;
			width: 100%;
		}

		&-title {
			font-size: 38px;
			text-transform: uppercase;
			font-family: $font-family-secondary;
			margin-bottom: $line;
		}

		&-description {
			font-family: $font-family-secondary;
			font-size: 15px;
			margin-bottom: $line;
		}

		&-checks {
			font-weight: 300;
			font-family: $font-family-secondary;
			position: relative;
			padding: 30px 0;

			&:before,
			&:after {
				content:'';
				height: 2px;
				width: 45px;
				background: color_('brown','light');
				margin:0 auto;
				display: block;
				position: relative;
				top: -18px;
			}

			&:after {
				top: 18px;
			}

			li {
				margin-bottom: $line-sm;
				//position: relative;

				&::before {
					content:"check";
					position: relative;
					left: -4px;
					top: 3px;
					height: 25px;
					width: 25px;
					color: color_('brown', 'light');

					@extend .icon;
				}
			}
		}

		&-prices {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: $line;
			font-family: $font-family-secondary;
			flex: 1;

			div {
				width: 100%;
				padding: 10px;
				text-align:center;

				@media screen and (min-width: map-get($grid-breakpoints,'sm')) and (max-width: map-get($grid-breakpoints,'lg')) {
					width: 100%;
					text-align: center;
				}

				strong {
					font-size: 28px;
					display: block;
					font-weight: 400;

					@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
						font-size:24px;
					}

					@media screen and (max-width: map-get($grid-breakpoints,'sm')) {
						font-size:20px;
					}
				}

				span {
					font-weight: 300;
					color: color_('gray','strong');
					text-transform: uppercase;
				}
			}
		}
	}

	&__box-inner {
		padding: 40px;

		@media screen and (max-width: map-get($grid-breakpoints,'lg')) {
			padding: 20px;
		}
	}
}
</style>
